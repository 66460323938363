.body {
    background-color: var(--surface-ground) !important;
    height: 100%;
    width: calc(100% - 80px);
    position: fixed;
    top: 0;
    left: 80px;
    margin: 0 !important;
}

.footer {
    height: 30px;
    width: calc(100% - 80px);
    left: 80px;
    position: fixed;
    bottom: 0;
    margin: 0 !important;
    padding: 0 !important;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: darkgrey;
}

.menuList {
    height: calc(100% - 190px);
    position: absolute;
    top: 120px;
    left: 0;
    overflow-y: scroll;
    overflow-x: hidden;
}

.menuBar {
    background-color: white;
    padding: 15px 0 323px;
    box-shadow: 0 2px 12px 2px rgba(0, 0, 0, 0.08);
    width: 80px;
    height: 100%;
    position: fixed !important;
    margin: 0 !important;
    left: 0 !important;
    transition: 0.2s ease;
    overflow: hidden !important;
}

.menuBar:hover {
    width: 240px;
    transition: 0.2s ease;
}

.headerLogo {
    height: 40px;
    width: 100%;
    object-fit: contain;
    position: absolute;
    display: inline-block;
    left: 0px;
    padding: 5px;
    margin: auto;
    cursor: pointer;
}

.headerButton {
    border-color: transparent !important;
    height: 44px;
    width: 44px;
    margin: 10px;
    background-color: white;
}

.menuItem {
    left: 0;
    height: 60px;
    padding: 0 !important;
    margin: 0 !important;
    margin-bottom: 20px !important;
}

.menuButton {
    border-color: transparent !important;
    height: 100%;
    width: 230px !important;
    background-color: white;
    position: relative;
    left: 0;
    text-align: center;
}

.selectedMenuButton {
    background-color: rgba(255, 144, 21, 0.08);
    border-left: 4px solid var(--orange) !important;
}

.menuButton img {
    position: absolute;
    left: 22px;
    top: 12px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    transition: 0.2s ease;
}

.menuButton:hover {
    background-color: rgba(255, 144, 21, 0.15);
    transition: 0.2s ease;
}

.menuButton .label {
    font-family: roboto;
    font-size: 16px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: var(--blue-vital);
    width: 100px;
    position: absolute;
    align-content: center;
    vertical-align: middle;
    text-align: left;
    float: right;
    left: 91px;
    top: 14px;
    margin: 0;
    height: 30px;
    padding: 0px;
    display: inline-flex;
    align-items: center;
}

.userButton {
    width: 40px;
    height: 40px;
    border: 1px var(--grey) solid;
    border-radius: 20px;
    background-color: var(--lightGrey);
}

.userButton:hover {
    background-color: var(--grey);
}

.profileMenuFooter {
    position: absolute !important;
    padding: 20px;
    bottom: 0;
}

@media (max-width: 768px) {
    .body {
        width: 100%; 
        left: 0;
        display: flex;
        flex-direction: column; 
    }
    .sidebar .userButton {
        z-index: 1000;
        width: 36px; 
        height: 36px;
        margin: 8px auto; 
    }
    .popover {
        z-index: 0; 
    }
}