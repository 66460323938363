.resourceCell {
    min-height: 84px;
    padding: 0 !important;
    margin: 0 !important;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.resourceNameCell {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.08);
    padding-bottom: 20px !important;
}

.resourceGrid {
    width: 100%;
}

.resourceLabel {
    font-family: roboto !important;
    font-size: 15px;
    text-align: left;
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
    border: none;
    background: none;
    overflow: hidden;
    text-overflow: ellipsis;
}

.resourceLabel:hover {
    font-weight: 600;
}

.resourceExtraHours {
    font-family: roboto-medium;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    min-width: 22px !important;
    height: 22px;
    padding: 2px !important;
    margin: 0 !important;
    border: none;
    background: none;
    overflow: hidden;
    position: absolute;
    right: 5px;
    bottom: 5px;
    color: white !important;
    border-radius: 12px;
}

.draggableShift {
    width: 94px !important;
    height: 54px !important;
    margin: 0px;
    margin-right: 10px;
    position: relative;
    display: inline-block;
}

.draggableShiftLabel {
    height: 34px !important;
    display: inline-flex;
    align-items: center;
    font-family: roboto;
    font-size: 14px;
}

.container {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0.1em 0.1em 0.3em rgba(0, 0, 0, 0.15);
    transition: 0.2s ease-in;
}

.groupeList {
    width: calc(100% - 200px);
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
}

.manageGroupesButton {
    height: 100%;
    background-color: transparent;
    border: none;
    font-size: 14px;
    font-family: roboto-medium;
    color: var(--orange);
    margin-right: 10px;
    margin-left: auto;
}

.manageGroupesButton:hover {
    background-color: var(--gray-200);
}

.samplesContainer {
    padding-right: 10px;
    width: auto;
    height: auto;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}