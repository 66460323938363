.custom-questions-tree .p-tree-toggler-icon {
    display: none;
} 

.custom-questions-tree .question > .p-treenode-content {
    background-color: #dfd7bf; /* Pastel Yellow */
    color: #333;
    padding: 0.5rem;
    border-radius: 5px;
}

.custom-questions-tree .p-treenode-content {
    padding-left: 0.5rem;
}

.custom-questions-tree .p-treenode-children {
    margin-left: 1.5rem;
    /* Ajuster la marge pour les enfants */
}

.custom-questions-tree .p-treenode-children .p-treenode-children {
    margin-left: 1.5rem;
    /* Ajouter une indentation supplémentaire pour les sous-catégories */
}

.custom-questions-tree .p-treenode-children .p-treenode-children .p-treenode-children {
    margin-left: 1.5rem;
    /* Ajouter une indentation supplémentaire pour les aptitudes */
}

.custom-questions-tree .p-tree-filter-container {
    margin-inline: 10px;
    width: calc(100% - 20px);
}

.custom-questions-tree .p-tree-container .p-treenode .p-tree-toggler:hover {
    color: inherit;
    background-color: inherit;
    border-color: inherit;
}