.custom-field-set .p-fieldset-legend {
    width: 20rem !important;
    margin-left: 2rem;
    font-weight: 400;
    font-size: 15px;
    background-color: #FFEEDC;
    border: 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0.1rem 0.8rem,
        rgba(0, 0, 0, 0.1) 0 0.1rem 0.6rem;
}
.custom-field-set .p-fieldset-content{
    border-bottom: 1px solid rgb(207, 203, 203);
    border-radius: 8px;
    margin: 2rem;
    box-shadow: inset rgba(0, 0, 0, 0.1) 0 0.1rem 0.8rem,
            inset rgba(0, 0, 0, 0.1) 0 0.1rem 0.6rem;

}