.background {
    background: url("../../assets/images/Slider-02c.jpg") #676770b3;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: darken;
    z-index: -1;
}

.fullHeight {
    height: 100%;
    min-height: 100vh;
}

.fullWidth {
    width: 100%;
}

.loginImage {
    height: 75%;
    position: absolute;
}

.logo {
    width: 60%;
}

.welcomeText {
    width: 100%!important;
    height: 35px;
    font-family: roboto;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: center;
    color: white;
}

.submainLabel {
    font-family: roboto-medium;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: normal;
    color: #fff;
}

.label {
    font-family: roboto-medium;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: normal;
    color: #fff;
    margin: 8px;
    margin-left: 18px !important;
    margin-right: 18px !important;
}

.inputText {
    font-family: roboto;
    width: 100%;
    height: 40px;
    border-radius: 20px;
    padding: 5px 8px 5px 18px;
    border: solid 1px var(--orange);
}

.errorInput {
    width: 100%;
    height: 12px;
    font-family: roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.86;
    letter-spacing: normal;
    color: #f73c2e;
    margin-left: 18px !important;
    margin-right: 18px !important;
}

.mainLabel {
    width: 100%;
    height: 38px;
    font-family: roboto;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: normal;
    color: var(--orange);
}

.textBtn {
    border: none;
    background-color: inherit;
    cursor: pointer;
    display: inline-block;
    font-family: roboto-medium;
    font-size: 15px;
    color: var(--grey);
}
.textBtn:hover {
    text-decoration: underline;
}

.loginContainer {
    box-shadow: 0 0 20px rgba(247, 247, 247, 0.5);
    border-radius: 10px;
    background: url("../../assets/images/Sphere-Filigrane.png") #012779;
    background-blend-mode: overlay;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgba(13, 55, 131, 1);
    padding: 25px;
}