.transparentSelect {
    border-radius: 5px !important;
    height: 34px !important;
    margin: 0;
    padding: 0;
    padding-left: 8px;
    padding-right: 8px;
    width: auto !important;
    min-width: 100px !important;
    background-color: transparent !important;
    font-family: roboto-medium;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    border: none !important;
    top: 0;
}

.imageButton {
    width: auto !important;
    border-color: transparent !important;
    margin: 0;
    background-color: transparent;
    transition: transform 0.2s;
}

.transparentSelect:hover {
    background-color: var(--lightGrey) !important;
}

.optionButton {
    font-family: roboto;
    height: 33px;
    width: calc(100% - 6px);
    font-size: 14px;
    background-color: transparent;
    border: none;
    text-align: left;
    margin: 3px;
    padding: 4px;
}

.optionButton:hover {
    background-color: var(--grey) !important;
}

.popover {
    max-width: 380px !important;
}

.transparentSelectPopover {
    padding: 0 !important;
    margin: 0 !important;
    min-width: 120px !important;
    max-height: 320px !important;
    overflow: scroll;
    border: none !important;
}

.colorPickerButton {
    border-radius: 50%;
    height: 30px !important;
    width: 30px !important;
    border: 0.5px solid var(--grey);
}

.colorPickerButton:hover {
    border: 1px solid grey;
}

.seletcedColorPicker {
    border: 1px solid var(--vital-blue) !important;
}

.inputText {
    font-family: roboto;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    width: 100% !important;
    font-size: 14px;
    border-radius: 20px !important;
    background-color: transparent !important;
    border: solid 1px var(--grey);
}

.inputNumber {
    font-family: roboto;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    width: 100% !important;
    font-size: 14px;
    border-radius: 20px !important;
    background-color: transparent !important;
    border: solid 1px var(--grey);
}

.inputText:focus .inputNumber:focus {
    outline: none;
    border: 1px solid var(--orange) !important;
}

.customSelectOption {
    display: flex;
    margin: 4px;
    padding: 4px;
}

.customSelectOption label {
    margin: 3px;
    font-family: roboto;
    font-size: 14px;
}

.multiSelectOption {
    display: flex;
    border-bottom: thin solid lightgray;
    margin: 4px;
    padding: 4px;
}

.multiSelectOption label {
    margin: 3px;
    font-family: roboto;
    font-size: 14px;
}

.multiSelectOption:hover, .customSelectOption:hover  {
    background-color: var(--grey) !important;
}

.inputWithSubmit > input{
    background-color: transparent !important;
    border: none !important;
    text-align: center !important;
}

.searchBarTeams{
    width: 100%;
}