
.ctable {
    border-collapse: collapse !important;
}

.ctable th {
    text-align: center;
    background-color: lightgray;
    border: 1px solid grey;
    border-collapse: collapse;
}

.ctable td {
    text-align: center;
    border: 1px solid grey;
    border-collapse: collapse;
}

.whiteCell {
    background-color: white;
}

.primaryCell0 {
    background-color: var(--indigo-50) !important;
}

.primaryCell1 {
    background-color: var(--indigo-100) !important;
}

.primaryCell2 {
    background-color: var(--indigo-200) !important;
}

.primaryCell3 {
    background-color: var(--indigo-300) !important;
}

.tableSeperator {
    height: 25px !important;
}