.p-datepicker {
    z-index: 1350 !important;
}

.custom-dropdown-panel {
    z-index: 1350 !important;
}

.p-stepper-number {
    background-color: lightgray;
}

.p-datatable-footer {
    height: 50px;
}

.custom-calendar.error,
.custom-dropdown.error,
.custom-input.error {
    border: 1px solid red;
    border-radius: 5px;
}

@media screen and (max-width: 768px) {
    .p-stepper.p-stepper-vertical .p-stepper-panel .p-stepper-content {
        width: 95%;
    }
}



/* .p-button {
    background-color: aqua;
} */