.groupeCard {
    margin-bottom: 20px;
    box-shadow: rgb(9 30 66 / 5%) 0px 10px 18px, rgb(95 106 126 / 5%) 0px 0px 1px;
}

.cardBody{
    overflow-x: scroll !important;
}

.cardHeader span{
    margin-left: 10px;
    font-size: 12px !important;
}