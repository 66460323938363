.teamLabel {
    height: 34px !important;
    margin: 0;
    padding: 0;
    padding-left: 8px;
    padding-right: 8px;
    width: auto !important;
    min-width: 100px !important;
    background-color: transparent !important;
    font-family: roboto-medium;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    border: none;
}

@media screen and (max-width: 768px) {
    .teamLabel {
        height: 34px !important;
        margin: 0;
        padding: 0;
        padding-right: 8px;
        width: auto !important;
        min-width: 100px !important;
        background-color: transparent !important;
        font-family: roboto-medium;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        border: none;
        align-content: center;
    }
    
}