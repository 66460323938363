.reportsView {
    font-family: var(--font-family);
    font-weight: normal;
    background: var(--surface-ground);
    color: var(--text-color);
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    width: 100%;
}

.card {
    background: var(--surface-card);
    padding: 2rem;
    margin: 15px;
    border-radius: 10px;
    margin-top: 80px;
    height: calc(100% - 80px);
}

@media screen and (max-width: 768px) {
    .reportsView {
        height: calc(100% - 90px);
        margin-top: 90px;
        width: 100%;
        overflow: scroll !important;
    }
}

@media (min-width : 769px) and (max-width: 1000px) {
    .reportsView {
        width: 100%;
        overflow: scroll !important;
    }
    .card {
        background: var(--surface-card);
        padding: 1rem;
        margin: 15px;
        border-radius: 10px;
        margin-top: 90px;
        height: 302px;;
    }
}
