.settingsView {
    height: 100%;
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
    padding-top: 30px;
    padding-left: 30px;
    overflow-y: auto !important;
}

.inputText {
    text-align: left;
    font-family: roboto;
    font-size: 18px;
    width: 100% !important;
    min-height: 40px !important;
    height: auto !important;
    border-radius: 20px;
    padding: 5px 8px 5px 18px;
    border: solid 1px #f5b826;
    background-color: white;
}

.label {
    font-family: roboto;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: normal;
    color: #000;
}

.menuTitle {
    font-family: roboto;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: normal;
    color: #000;
}

.leftMenu {
    background-color: var(--orange-50);
    padding: 30px;
    margin: 0 !important;
    position: -webkit-sticky !important;
    position: fixed !important;
    height: 100%;
    width: 220px;
    top: 0;
    box-shadow: 0.1em 0.1em 0.5em rgba(0, 0, 0, 0.15);
}

.leftMenuTitle {
    font-family: roboto;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--blue-vital);
    margin-bottom: 30px;
    padding-left: 10px;
}

.menuContent {
    width: calc(100% - 260px);
    margin: 20px;
    margin-left: 240px;
    margin-top: 40px;
    border-radius: 10px;
    background-color: white;
    padding: 10px;
    box-shadow: rgb(9 30 66 / 5%) 0px 10px 18px, rgb(95 106 126 / 5%) 0px 0px 1px;
}
.menuContentV {
    width: calc(100% - 10px);
    margin: 20px;
    margin-top: 40px;
    margin-left: 0;
    border-radius: 10px;
    background-color: white;
    padding: 10px;
    box-shadow: rgb(9 30 66 / 5%) 0px 10px 18px, rgb(95 106 126 / 5%) 0px 0px 1px;
}
.versionLabel{
    font-family: roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: darkgray;
    margin-bottom: 30px;
    padding-left: 10px;
    position: fixed;
    bottom: 0;
    left: 110px;
}
.settingsButton {
    border-radius: 8px !important;
    height: 34px !important;
    width: 100%;
    padding-left: 10px;
    margin-bottom: 10px;
    min-width: 120px !important;
    background-color: transparent;
    text-align: left;
    border: none;
    top: 0;
}

.settingsButton:hover {
    background-color: rgba(255, 144, 21, 0.15) !important;
}

.menuLabel {
    font-family: roboto;
    color: grey;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.selectedLabel {
    font-family: roboto-medium;
    color: black;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.itemsList label {
    font-family: roboto-medium;
    color: rgba(255, 144, 21, 1);
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.itemsList span {
    font-family: roboto;
    color: black;
    font-size: 14px;
}

.itemLine {
    border-bottom: 1px solid var(--grey);
}
@media (max-width: 1199px) {
    .menuContentV {
        width: calc(100% - 260px);
        margin: 20px;
        margin-left: 240px;
        margin-top: 40px;
        border-radius: 10px;
        background-color: white;
        padding: 10px;
        box-shadow: rgb(9 30 66 / 5%) 0px 10px 18px, rgb(95 106 126 / 5%) 0px 0px 1px;
    }
}
.rowShadeGreen{
    /* background-color: #d6f1d6 !important; */
}
.rowShadeRed{
    background-color: #ffcbc1 !important;
}



@media screen and (max-width: 768px) {

    .leftMenu {
        background-color: var(--orange-50);
        padding: 15px;
        margin: 0 !important;
        position: -webkit-sticky !important;
        position: fixed !important;
        height: 100%;
        width: 125px;
        left: 0;
        box-shadow: 0.1em 0.1em 0.5em rgba(0, 0, 0, 0.15);
    }
    
    .leftMenuTitle {
        font-family: roboto;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--blue-vital);
        margin-bottom: 30px;
        padding-left: 10px;
    }
    .menuContent {
        width: 100%;
        margin: 0;
        margin-top: 90px;
        border-radius: 10px;
        background-color: white;
        box-shadow: rgb(9 30 66 / 5%) 0px 10px 18px, rgb(95 106 126 / 5%) 0px 0px 1px;
    }
    .menuContentV {
        width: 100%;
        margin-left: 20px;
        /* margin-left: 145px; */
        /* margin-right: 5px; */
        margin-top: 55px;
        border-radius: 10px;
        background-color: white;
        box-shadow: rgb(9 30 66 / 5%) 0px 10px 18px, rgb(95 106 126 / 5%) 0px 0px 1px;
    }

    .versionLabel {
        left: 0;
    }
    
}