.datesHeader {
    background-color: var(--bluegray-50);
    width: 100%;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.08);
}

.layout {
    background: #eee;
}

.topSticky {
    position: -webkit-sticky !important;
    top: 0px !important;
    position: sticky !important;
    z-index: 999;
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.datesRow {
    width: 100%;
    display: inline-block !important;
}

.datesGridLayout{
    width: 100%;
}

.tableHeaderLabel {
    margin-top: 10px;
    width: 100%;
    height: 40px;
    display: inline-block;
}

.resourcesHeader {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.08);
}

.groupeLabel {
    height: 100%;
    width: 100%;
    margin: auto;
    font-family: roboto-medium !important;
    font-size: 13px;
    color: var(--blue-vital);
}

.categoryView {
    background-color: rgba(255, 144, 21, 0.15);
}

.teamName {
    width: 400px !important;
    white-space: nowrap;
    padding: 5px;
    position: -webkit-sticky !important;
    left: 0 !important;
    position: sticky !important;
    z-index: 998;
    display: inline-block;
}