.p-tree .p-tree-container .p-treenode .p-treenode-content {
    cursor: pointer;
    margin: 1rem;
    border: 1px solid rgb(226, 224, 224);
    /* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2); */
}

.customTabView .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav .p-tabview-nav-link {
    color: rgb(0, 0, 0);
    text-decoration: none;

}

.customTabView .p-tabview-nav-container .p-tabview-nav-content .p-tabview-selected .p-tabview-nav-link {
    color: rgb(0, 0, 0);
    text-decoration: none;
    border-color: #FF8800;
}

.p-inputotp .p-inputtext {
    padding: 5px 0px;
}

.customDialog .p-dialog-content {
    overflow-y: hidden;
}

/* .customDialog .p-dialog-header{
    background-color: #EFF3F8;
}
.customDialog .p-dialog-content{
    background-color: #eff3f8;
}
.customDialog .p-dialog-footer{
    background-color: #EFF3F8;
} */
.p-slider .p-slider-range {
    background-color: orange;
}

.p-slider .p-slider-handle {
    border-color: orange;
    transition: transform 0.3s ease;
}

.customDataTable .p-datatable-wrapper .p-datatable-table .p-datatable-thead tr th .p-column-header-content .p-column-title {
    width: 100%;
}

.header-container {
    display: flex;
    width: 100%;
    height: 32px;
}

.customTabView {
    height: 20px !important;
    z-index: 1300;
}

.customTabView .p-tabview-panels {
    height: 0px !important;
    padding: 0px !important;
    margin: 5px;
}

.p-tabview-nav-prev, .p-tabview-nav-next  {
    height: 59px;
}

.row {
    --bs-gutter-x: 0 !important;
}

.custom-marker {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
    cursor: pointer;
}

.custom-marker-focus {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
    cursor: pointer;
    transform: scale(1.3);
}

.custom-timeline .p-timeline-event .p-timeline-event-separator {
    height: 220px;
}

/* .custom-timeline .p-timeline-event .p-timeline-event-separator .p-timeline-event-connector{
    height: 180px;
    width: 2px !important;
    color: #e5e7eb;
    flex-grow: 1;
    z-index: 100000000000000;
} */
.customDialog .p-dialog-content {
    padding: 0px;
    height: 100% !important;
}

/* Appliquer un style personnalisé pour le composant Tree */
.custom-tree .p-treenode-content {
    padding-left: 0.5rem;
}

.custom-tree .p-treenode-children {
    margin-left: 1.5rem;
    /* Ajuster la marge pour les enfants */
}

.custom-tree .p-treenode-children .p-treenode-children {
    margin-left: 1.5rem;
    /* Ajouter une indentation supplémentaire pour les sous-catégories */
}

.custom-tree .p-treenode-children .p-treenode-children .p-treenode-children {
    margin-left: 1.5rem;
    /* Ajouter une indentation supplémentaire pour les aptitudes */
}

.custom-tree .p-tree-filter-container {
    margin-inline: 10px;
    width: calc(100% - 20px);
}

.custom-tree .p-tree-container .p-treenode .p-tree-toggler:hover {
    color: inherit;
    background-color: inherit;
    border-color: inherit;
}

.icon-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.icon-button:hover {
    color: #007bff;
    /* Change la couleur au survol */
}

.shake-animation {
    animation: shake 0.5s;
}

.custom-multiselect {
    width: 100%;
    /* Ajuster la largeur pour qu'elle prenne tout l'espace disponible */
}

.custom-multiselect .p-multiselect {
    width: 100%;
}

.custom-multiselect .p-multiselect-panel {
    max-width: 100%;
    /* Limite la largeur maximale du panneau de sélection */
}

.custom-multiselect .p-multiselect-label {
    white-space: normal;
}

.modal-backdrop-blur {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1100;
}

.custom-select {
    display: block;
    width: 100%;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-select option {
    padding: 0.5rem;
}

.p-checkbox.p-highlight .p-checkbox-box {
    border-color: #FF9015;
    background-color: #FF9015;
}

.fade {
    z-index: 1200 !important;
}




@media (max-width: 768px) {
    .custom-select {
        width: 100%;
        /* Utilisez toute la largeur disponible pour éviter le débordement */
        z-index: 1050;
        /* Assurez-vous que la liste déroulante apparaît au-dessus des autres éléments */
        margin: 5px 0;
        /* Ajoutez une petite marge pour l'esthétique */
        max-height: 150px;
        font-size: 12px;
    }

    .modal-content {
        z-index: 1040;
        overflow: visible;
        /* Permet aux éléments débordants de rester visibles */
    }

    /* Augmentez l'espace autour des éléments de formulaire pour éviter que les listes déroulantes se chevauchent avec d'autres champs */
    .form-row {
        margin-bottom: 15px;
    }
}




@keyframes shake {

    0%,
    100% {
        transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateX(-5px);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translateX(5px);
    }
}

.shake-animation {
    animation: shake 0.5s ease-in-out;
}

.node-container {
    position: relative;
    display: flex;
    align-items: center;

    height: 20px;
    width: 100%;
}

.node-button {
    display: none;
    margin-left: auto;
}

.node-container:hover .node-button {
    display: inline-block;
}
/*TODO discuss with Charbel*/
/* @media screen and (max-width: 768px) {
    .node-button {
        display: inline;
        margin-left: auto;
    }
} */
/* Styles pour les catégories, sous-catégories et aptitudes */

.custom-tree .p-treenode-content {
    padding: 0.5rem;
    border-radius: 5px;
    margin-block: 0 !important;
}

.custom-tree .category>.p-treenode-content {
    background-color: #b2cbde;
    /* Pastel Pale Green */
    color: #333;
    padding: 0;
    height: fit-content;
    padding-block: 10px;
}

.custom-tree .subcategory>.p-treenode-content {
    background-color: #d8e1e8;
    /* Pastel Light Blue */
    color: #333;
    margin-left: 1rem;
    padding: 0;
    height: fit-content;
    padding-block: 10px;
}

.custom-tree .aptitude>.p-treenode-content {
    background-color: var(--orange-100);
    /* Pastel Peach Puff (orange) */
    color: #333;
    margin-left: 2rem;
    padding: 0;
    height: fit-content;
    padding-block: 10px;
}

.custom-tree .inactive>.p-treenode-content {
    background-color: #FF6961;
    /* Pastel Peach Puff (orange) */
    color: #333;
    margin-left: 2rem;
    padding: 0;
    height: fit-content;
    padding-block: 10px;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
    cursor: pointer;
    margin: 0.4rem;
}

/* .p-tree .p-tree-container .p-treenode-droppoint{
    height: 0px !important;
} */
.save-button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.save-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    margin-right: 5px;
    margin-bottom: 10px;
    padding: 0.75rem 1.5rem imo !important;
    border-radius: 5px;
    /* Ajout du radius */
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.save-button:hover {
    background-color: #0056b3;
}

.incrementBtn {
    background-color: #ff9015;
}

.p-orderlist-controls {
    display: none;
}

.p-orderlist-controls-list {
    margin-bottom: 0;
}
