.collabMcqModal .p-dialog-header-icons {
    display: none;
}

.scard {
    background: #FFEEDC !important;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px !important;
}

.options-container {
    margin-top: 10px;
}

.option-label {
    display: flex;
    padding: 10px;
    margin-bottom: 10px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    cursor: pointer;
    align-items: center;
    padding-block: 15px;

}

.option-label input[type="radio"] {
    margin-right: 10px;
}

.option-label input[type="checkbox"] {
    margin-right: 10px;
    border-radius: 0 !important;
}
@media (max-width: 768px) {
    .mcq-container {
        min-width: 100vw !important;
        max-width: 100vw !important;
        margin: 20px auto;
        padding: 20px;
        /* background: #fcf8f4;
        border-radius: 8px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); */
    }
}
@media (min-width: 769px) {
    .mcq-container {
        min-width: 40vw;
        max-width: 60vw;
        margin: 20px auto;
        padding: 20px;
        /* background: #fcf8f4;
        border-radius: 8px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); */
    }
}