.containerView {
    margin-top: 0px;
    padding: 0;
    height: 100%;
    width: 100%;
}

.monthlyCard {
    background: var(--surface-card);
    padding: 2rem;
    margin: 15px;
    border-radius: 10px;
    margin-top: 80px;
    height: calc(100% - 80px);
}

.dailyCard {
    background: var(--surface-card);
    padding: 2rem;
    margin: 15px;
    border-radius: 10px;
    margin-top: 10px;
    height: calc(100% - 110px);
}

@media screen and (max-width: 768px) {
    .monthlyCard {
        background: var(--surface-card);
        padding: 1rem;
        margin: 5px;
        border-radius: 10px;
        margin-top: 110px;
        height: calc(100% - 120px);
    }
    .dailyCard {
        background: var(--surface-card);
        padding: 1rem;
        margin: 5px;
        border-radius: 10px;
        margin-top: 160px;
        height: calc(100% - 120px);
    }
}

@media (min-width: 769px) and (max-width: 1000px) {
    .monthlyCard {
        background: var(--surface-card);
        padding: 1rem;
        margin: 15px;
        border-radius: 10px;
        margin-top: 90px;
        height: calc(100% - 80px);

    }
    .dailyCard {
        background: var(--surface-card);
        padding: 1rem;
        margin: 15px;
        border-radius: 10px;
        margin-top: 90px;
        height: calc(100% - 100px);
    }
}

