

.projectLogo {
    object-fit: contain;
    width: 100px;
    height: 100px;
    border-radius: 10%;
    background-color: #f0f0f0;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0.1rem 0.8rem,
        rgba(0, 0, 0, 0.1) 0 0.1rem 0.6rem;
}

.projectCard {
    height: 100% !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0.1rem 0.8rem,
        rgba(0, 0, 0, 0.1) 0 0.1rem 0.6rem;
    transition: transform 0.3s ease;
}
/* 
.projectCard:hover {
    transform: scale(1.05);
    cursor: pointer;
} */

.textShadow {
    font-size: 24px;
    color: rgb(95, 93, 93);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.tabViewBlock {
    overflow-y: auto;
    height: calc(100vh - 190px);
}

.progressionBarMain {
    padding: 20px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0.1rem 0.8rem,
        rgba(0, 0, 0, 0.1) 0 0.1rem 0.6rem;
    transition: transform 0.3s ease;
}

.progressionCollabsList {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 450px);
    padding: 20px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0.1rem 0.8rem,
        rgba(0, 0, 0, 0.1) 0 0.1rem 0.6rem;
    transition: transform 0.3s ease;
}

.vcompViewLeftTable {
    box-shadow: rgba(0, 0, 0, 0.1) 0 0.1rem 0.8rem,
        rgba(0, 0, 0, 0.1) 0 0.1rem 0.6rem;
    transition: transform 0.3s ease;
    border-radius: 10px;
    height: calc(100vh - 320px);
}

.imageUploadContainer {
    position: relative;
    cursor: pointer;
    height: 200px;
    transition: transform 0.2s ease;
}

.imageUploadContainer:hover {
    position: relative;
    cursor: pointer;
    height: 200px;
}

.imageContainer {
    position: relative;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    border-radius: 8%;
    width: 200px;
    height: 200px;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: rgba(0, 0, 0, 0.1) 0 0.1rem 0.8rem,
        rgba(0, 0, 0, 0.1) 0 0.1rem 0.6rem;
}


.addImageBlock {
    display: block;
    border-radius: 8%;
    max-width: 200px;
    max-height: 200px;
    width: auto;
    height: auto;
}

.addImageBlockIcon {
    display: block;
    border-radius: 6px;
    width: 40px !important;
    height: 40px !important;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: transform 0.3s ease;
    margin-bottom: 5px;
    margin-left: 5px;
    object-fit: contain;
}

.addImageBlockIcon:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.deleteButton {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.deleteButton:hover {
    transform: scale(1.08);
    cursor: pointer;
}

.decrementButtonStyle {
    visibility: hidden;
}

.spanImageContainer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: rgb(167, 154, 154);
    cursor: pointer;
}

.hoverEffect {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.hoverEffect::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    height: 300px;
    background-image: radial-gradient(circle, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 70%);
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.5s ease, opacity 0.3s ease;
    pointer-events: none;
    opacity: 0;
}

.hoverEffect:hover::after {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
}

.stepperContainer {
    background-color: white;
    height: calc(100vh - 190px) !important;
    overflow-y: auto;
}





.mainContainer {
    display: flex;
    height: 100vh;
    background-color: var(--surface-ground) !important;
    width: 100%;
}

.leftContainer {
    height: 100%;
    background-color: var(--orange-100);
    overflow-y: hidden;
}

.rightContainer {
    font-weight: bold;
    overflow-y: auto !important;
    height: 100% !important;
}

.menuLeft {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 20px;
}

.menuLeft li {
    padding: 16px 16px;
    margin-bottom: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    text-align: center;
}

.menuLeft li:hover {
    background-color: #e0e0e0;
}

/* .menuLeft li.selected {
    background-color: white; 
    font-weight: bold; 
    box-shadow: rgba(0, 0, 0, 0.2) 0 0.1rem 0.8rem,
                rgba(0, 0, 0, 0.2) 0 0.1rem 0.6rem;
} */
.menuLeft li.selected {
    background-color: var(--surface-ground);
    font-weight: bold;
}

.menuButtonsLeft{
    position: absolute;
    bottom: 50px;
    width: 10%;
}

@media screen and (max-width: 768px ) {
    .mainContainer {
        display: block;
     
    }
    .leftContainer {
        display: none;
    }
    .rightContainer {
        height: 100vh !important;
        overflow-y: auto;
    }

    .custom-width-prev {
        width: 15% !important;
    }
    
    .custom-width-next {
        width: 48% !important;
    }
    
    
}
@media screen and (min-width: 769px) and (max-width: 1000px) {
    .mainContainer {
        display: block;
     
    }
    .leftContainer {
        display: none;
    }
    .rightContainer {
        height: calc(100vh - 150px) !important;
        overflow-y: auto;
    }

    .custom-width-prev {
        width: 15% !important;
    }
    
    .custom-width-next {
        width: 48% !important;
    }
}
.incrementBtn{
    background-color: #ff9015;
    border-top-right-radius: 6px;
}
.decrementBtn{
    background-color: #ff9015;
    border-bottom-right-radius: 6px;
    background-color:  #ff9015;

}
.clearButton {
    background-color: #fab710; 
    color: white; 
    padding: 10px 20px; 
    border: none; 
    border-radius: 5px; 
    cursor: pointer; 
    transition: background-color 0.3s; 
    font-weight: bold;
    margin-left: 10px
  }
  
  .clearButton:hover {
    background-color: #d69e0e;
  }
