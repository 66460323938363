.customMultiselect {
    max-height: 15rem !important;
    width: 100%;
}
.customMultiselect .p-multiselect-label{
  white-space: initial;
}
.customMultiselect .p-multiselect-label-container{
  overflow-y: scroll;
  overflow-x: hidden;
}
.customMultiselect.p-multiselect.p-multiselect-chip .p-multiselect-token {
  margin-bottom: 0.5rem !important;
} 