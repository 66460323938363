.background{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(31, 14, 0, 0.2);
    margin: 0!important;
    z-index: 999999;
}

.loader{
    position: absolute;
    margin: auto;
}

.fullHeight{
    height: 100%;
    width: 100%;
    position: absolute;
}