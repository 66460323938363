.results-container {
    max-height: 80vh;
    overflow-y: auto;
    padding: 20px;    
  }
  
  .aptitude-section {
    margin-bottom: 20px;
    padding: 15px;
    background: #fffff0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .questions-container {
    margin-top: 10px;
  }
  
  .question-block {
    margin-top: 10px;
    padding: 10px;
    background:#FFEEDC ;
    border: 1px solid #dde;
    border-radius: 3px;
  }
  
  .option-item {
    padding: 5px;
    list-style-type: none;
    margin-block: 5px;
  }
  
 .correct-answer {
  color: green; 
  /* background-color: #cfc; */
  font-weight: lighter !important;
}

.collab-correct-response {
  color: green;
  background-color: #cfc;
  font-weight: bold !important; 
}

.collab-wrong {
  color: #900;
  font-weight: bold; 
  background-color: rgb(255, 204, 204);
}