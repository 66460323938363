/* EvaluationsView.module.css */
.dialogCustom {
    width: 55rem;
    height: fit-content;
}

.dialogContent {
    border-radius: 50px !important; /* Apply border-radius */
}
.dialogHeader, .dialogFooter {
    border-radius: 25px; /* Adjust the radius as needed */
}
.containerView {
    margin-top: 0px;
    padding: 0;
    height: 100%;
    width: 100%;
}

.groupesView {
    height: calc(100% - 65px);
    margin-top: 65px;
    width: 100%;
    overflow: scroll !important;
    padding: 15px;
}

.collabsCard {
    background: var(--surface-card);
    padding: 2rem;
    margin: 15px;
    border-radius: 10px;
    margin-top: 85px;
    height: calc(100% - 110px);
}

.emptyPageImg{
    width: 100px !important;
    height: 100px;
    padding: 0 !important;
    align-self:last baseline;
    margin-bottom: 15px;
}

.noGroupstext {
    margin: auto;
    text-align: center;
    border: none;
    background-color: inherit;
    cursor: pointer;
    display: inline-block;
    font-family: roboto-medium;
    font-size: 16px;
    color: gray;
}

.textBtn {
    margin: auto;
    border: none;
    background-color: inherit;
    cursor: pointer;
    display: inline-block;
    font-family: roboto-medium;
    font-size: 16px;
    color: var(--orange);
}
.colorRatioButton {
    border-radius: 50%;
    height: 35px !important;
    width: 35px !important;
    border: 0px solid #e2dcdc;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5); 
    transition: box-shadow 0.3s; 
}
.colorRatioButton:hover {
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.8); 
}
.colorRatioButtonLocked {
    border-radius: 30% 50% 30% 50%;
    height: 30px !important;
    width: 30px !important;
    border: 0 solid var(--grey);
}
.tooltip {
    background-color: #fff9f1;
    border-radius: 6px;
    border: 1px var(--orange) solid;
    color: #461f01;
    padding: 6px;
    font-family: roboto;
    font-size: 13px;
    font-weight: normal;
    box-shadow: 0.1em 0.1em 1em rgba(0, 0, 0, 0.3);
}

.mobileBody {
    margin-top: 120px;
}

@media screen and (max-width: 768px) {
    .collabsCard {
        background: var(--surface-card);
        padding: 2rem;
        margin: 15px;
        border-radius: 10px;
        margin-top: 130px;
        height: calc(100% - 145px);
    }
}

@media (min-width: 769px) and (max-width: 1000px) {
    .collabsCard {
        background: var(--surface-card);
        padding: 1rem;
        margin: 15px;
        border-radius: 10px;
        margin-top: 80px;
        height: calc(100% - 85px);
    }
}