.dateCell {
    border-radius: 3px;
    text-align: center !important;
    width: 100% !important;
    height: 100%;
    border-color: transparent !important;
    background-color: rgba(255, 144, 21, 0.18);
    overflow: hidden;
    align-items: center !important;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}

.dateCellOff {
    background: linear-gradient(-45deg, var(--gray-300) 12.5%, transparent 12.5%, transparent 50%, var(--gray-300) 50%, var(--gray-300) 62.5%, transparent 62.5%, transparent) 0% 0% / 7px 7px !important;
}

.dateCell label {
    margin: 5px;
    width: 100%;
    text-align: center !important;
    font-size: 16px;
    font-family: roboto-medium;
}

.shiftCell {
    border-radius: 4px;
    width: auto !important;
    height: 100%;
    border-color: transparent;
    margin: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
    cursor: grab;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}

.shiftCell label {
    overflow: hidden;
    cursor: grab;
}

.sampleStartDate {
    top: 3px;
    left: 10px;
    position: absolute;
    text-align: left;
    width: calc(50% - 10px);
    height: 16px;
    font-size: 14px;
    font-family: roboto;
    font-weight: 600;
    align-items: top;
    color: var(--blue-vital);
}

.sampleEndDate {
    top: 3px;
    right: 6px;
    position: absolute;
    text-align: right;
    align-items: top;
    width: calc(50% - 6px);
    height: 16px;
    font-size: 12px;
    font-family: roboto;
    color: var(--blue-vital);
}

.startDate {
    top: 5px;
    right: 7px;
    position: absolute;
    text-align: right;
    width: calc(50% - 10px);
    height: 15px;
    font-size: 11px;
    font-family: roboto-medium;
    align-items: top;
    color: var(--blue-vital);
}

.endDate {
    top: 20px;
    right: 7px;
    position: absolute;
    text-align: right;
    align-items: top;
    width: calc(50% - 10px);
    height: 15px;
    font-size: 11px;
    font-family: roboto-medium;
    color: var(--blue-vital);
}

.cellTagsContainer {
    width: 80%;
    height: 26px;
    top: 20px;
    right: 4px;
    position: absolute !important;
    text-align: right;
    align-items: center;
    align-content: end;
    display: flex;
    justify-content: end;
}

.extraTime {
    font-size: 12px;
    font-family: roboto-medium;
    font-weight: 500;
    border-radius: 11px;
    min-width: 22px;
    height: 22px;
    text-align: center;
    padding: 2px;
    margin-right: 2px !important;
    border: none;
    background: none;
    overflow: hidden;
    color: white !important;
    position: relative;
}

.cellPicto {
    width: 22px;
    height: 22px;
    margin-right: 2px !important;
    border-radius: 11px !important;
    background-color: #FA9016 !important;
    padding: 2px !important;
    position: relative;
    object-fit: contain;
}


.cellIcon {
    width: 20px;
    height: 40%;
    border-radius: 14px !important;
    bottom: 3px;
    right: 5px;
    /* background-color: #FA9016 !important; */
    /* padding: 4px !important; */
    position: absolute !important;
    object-fit: contain;
    display: block;
}

.title {
    left: 10px;
    bottom: 2px;
    position: absolute;
    text-align: left;
    align-items: bottom;
    text-overflow: ellipsis;
    /* enables ellipsis */
    white-space: nowrap;
    /* keeps the text in a single line */
    overflow: hidden;
    width: calc(100% - 20px);
    height: 20px;
    font-size: 15px;
    font-family: roboto;
}

.centerTitle {
    top: calc(50% - 20px);
    left: 10px;
    position: absolute;
    margin: auto;
    text-align: center !important;
    overflow: hidden;
    height: 40px;
    width: calc(100% - 20px);
    font-size: 15px;
    font-family: roboto-medium;
    align-items: center;
    display: flex;
}

.shiftCell .line {
    display: inline-block;
    left: 0;
    width: 8px;
    height: 100%;
}

.shiftCell:hover {
    filter: brightness(90%);
}

.selectedCell {
    top: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
}

.shiftPlaceholder {
    border-radius: 4px;
    display: flex !important;
    align-items: center !important;
    width: auto !important;
    height: 100%;
    border-color: transparent !important;
    margin: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    border-radius: 5px;
}

.shiftPlaceholder:hover {
    background-color: rgba(255, 144, 21, 0.2) !important;
}

.groupeCell {
    display: flex;
    height: 100%;
    min-width: 50px;
    max-width: 150px;
    width: auto;
    overflow: hidden;
}

.groupeCell button {
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: none;
    font-size: 14px;
    font-family: roboto;
    color: var(--blue-vital);
    margin-left: 10px;
    margin-right: 10px;
    white-space: nowrap;
}

.groupeCell button:hover {
    background-color: var(--gray-200);
}

.selected {
    font-weight: 600 !important;
    border-bottom: 2px solid var(--orange) !important;
}

.dateCell span {
    font-family: roboto-medium !important;
    font-size: 14px;
    text-align: center;
    width: 15px !important;
    padding: 0 !important;
}

.matinCount {
    color: var(--green);
}

.soirCount {
    color: var(--orange);
}

.nuitCount {
    color: var(--blue);
}

.tooltip {
    background-color: #fff9f1;
    border-radius: 6px;
    border: 1px var(--orange) solid;
    color: #461f01;
    padding: 6px;
    font-family: roboto;
    font-size: 13px;
    font-weight: normal;
    box-shadow: 0.1em 0.1em 1em rgba(0, 0, 0, 0.3);
    min-width: 100px;
}