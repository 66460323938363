.collabsCard {
    background: var(--surface-card);
    padding: 0;
    margin: 15px;
    border-radius: 10px;
    margin-top: 85px;
    overflow-y: auto;
    height: calc(100vh - 100px);
}
.mobileBody {
    margin-top: 120px;
}
.projectsRow{
}

@media screen and (max-width: 768px) {
    .collabsCard {
        background: var(--surface-card);
        padding: 0;
        margin: 15px;
        border-radius: 10px;
        margin-top: 150px;
        height: calc(100vh - 165px);
    }
}

@media (min-width: 769px) and (max-width: 1000px) {
    .collabsCard {
        background: var(--surface-card);
        padding: 0;
        margin: 15px;
        border-radius: 10px;
        margin-top: 115px;
        height: calc(100vh - 130px);
    }
}

