.intercellButton {
    border-color: transparent !important;
    height: 0px !important;
    top: 0;
    margin: 0px !important;
    padding: 0px !important;
    width: calc(100% - 40px);
    background-color: transparent;
    border-radius: 4px;
}

.intercellButton:hover {
    height: 26px;
}

.intercellButton:hover img {
    height: 26px;
}

.intercellButton img {
    height: 0px;
}

.primaryButton {
    border-radius: 20px !important;
    height: 40px !important;
    width: 100% !important;
    min-width: 100px !important;
    background-color: #fff !important;
    font-family: roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--orange)b !important;
    border: solid 2px var(--orange) !important;
}

.primaryButton:hover {
    background-color: var(--orange) !important;
    color: white !important;
}

.dangerButton {
    border-radius: 20px !important;
    height: 40px !important;
    width: 100% !important;
    min-width: 100px !important;
    background-color: #fff !important;
    font-family: roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--orange)b !important;
    border: solid 2px var(--red) !important;
}

.dangerButton:hover {
    background-color: var(--red) !important;
    color: white !important;
}

.imageButton {
    width: auto !important;
    border-color: transparent !important;
    margin: 0;
    background-color: transparent;
}

.floatingButton {
    position: absolute;
    right: 15px;
    bottom: 15px;
    width: 54px !important;
    height: 54px !important;
    border-color: transparent !important;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border-radius: 27px;
    border: none;
    box-shadow: 0.1em 0.1em 1em rgba(0, 0, 0, 0.15);
    z-index: 999;
}

.floatingButton img {
    width: 100%;
    height: 100%;
}

.floatingButton:hover {
    filter: brightness(80%);
}

.floatingDeleteContainer {
    position: absolute;
    bottom: 15px;
    left: 0;
    width: 100% !important;
    height: 44px !important;
    margin: auto;
    padding: 0;
    z-index: 998;
    display: flex;
    justify-content: center;
}

.floatingDeleteButton {
    position: relative;
    min-width: 116px !important;
    height: 44px !important;
    margin: auto;
    margin-right: 4px;
    padding: 0;
    border-radius: 27px !important;
    border: 2px solid var(--red-500);
    background-color: #fbb5babb !important;
    box-shadow: 0.1em 0.1em 1em rgba(0, 0, 0, 0.15);
    text-align: center;
    justify-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--red-500) !important;
    font-family: roboto;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
}

.floatingDeleteButton img {
    width: 28px;
    height: 28px;
}

.floatingDeleteButton:hover {
    filter: brightness(80%);
}

.floatingCancelButton {
    position: relative;
    width: 44px !important;
    height: 44px !important;
    margin: auto;
    margin-left: 4px;
    padding: 0;
    border-radius: 27px;
    border: none;
    background-color: #FFACB155 !important;
    box-shadow: 0.1em 0.1em 1em rgba(0, 0, 0, 0.15);
    filter: invert(1) grayscale(100%) brightness(200%);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.floatingCancelButton:hover {
    background-color: lightgray !important;
}

.radioButton {}

.radioButton>input[type="radio"] {
    display: none;
}

.radioButton>input[type="radio"]+*::before {
    cursor: pointer;
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 1rem;
    height: 1rem;
    margin: 0;
    margin-right: 0.3rem;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.1rem;
    border-color: var(--orange)b;
}

.radioButton>input[type="radio"]:checked+*::before {
    background: radial-gradient(var(--orange) 0%, #f5b826 40%, transparent 50%, transparent);
    border-color: var(--orange);
}

.radioButton>input[type="radio"]:checked+* {}

.tooltip {
    background-color: #fff9f1;
    border-radius: 6px;
    border: 1px var(--orange) solid;
    color: #461f01;
    padding: 6px;
    font-family: roboto;
    font-size: 13px;
    font-weight: normal;
    box-shadow: 0.1em 0.1em 1em rgba(0, 0, 0, 0.3);
}

.stateButton {
    border-radius: 15px !important;
    height: 30px !important;
    width: auto !important;
    min-width: 80px !important;
    background-color: #fff !important;
    font-family: roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--orange) !important;
    border: solid 2px var(--orange) !important;
}

.stateButton:hover {
    background-color: var(--orange) !important;
    color: white !important;
}

.selected {
    background-color: var(--orange) !important;
    color: white !important;
}

.transparentDatePicker {
    border-radius: 15px !important;
    height: 34px !important;
    margin: 0;
    padding: 0;
    padding-left: 8px;
    padding-right: 8px;
    width: auto !important;
    min-width: 120px !important;
    background-color: transparent !important;
    font-family: roboto-medium;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    border: none;
    top: 0;
}

.dateLabel {
    height: 34px !important;
    margin: 0;
    padding: 6px;
    padding-left: 2px;
    padding-right: 20px;
    width: auto !important;
    min-width: 120px !important;
    background-color: transparent !important;
    font-family: roboto-medium;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
}

.transparentDatePicker:hover {
    background-color: var(--lightGrey) !important;
}

.monthButton {
    font-family: roboto;
    height: 40px;
    width: 100%;
    font-size: 14px;
    border-radius: 4px !important;
    background-color: transparent;
    border: none;
}

.monthButton:hover {
    background-color: var(--grey) !important;
}

.yearButton {
    font-family: roboto-medium;
    font-size: 16px;
    border-radius: 4px !important;
    background-color: transparent;
    border: none;
    height: 30px;
}

.yearButton:hover {
    background-color: var(--lightGrey) !important;
}

.arrowButton {
    height: 30px;
    border-radius: 4px !important;
    background-color: transparent !important;
    border: none;
}

.arrowButton:hover {
    background-color: var(--lightGrey) !important;
}

.weekRow {
    padding: 0 !important;
    margin: 0 !important;
}

.weekRow:hover {
    background-color: var(--lightGrey);
}

.dayLabel {
    color: var(--vital-blue);
}

.activeDayButton {
    font-family: roboto;
    height: 40px;
    width: 100%;
    font-size: 14px;
    background-color: transparent;
    border: none;
}

.activeDayButton:hover {
    background-color: var(--grey) !important;
}

.deactiveDayButton {
    font-family: roboto;
    height: 40px;
    width: 100%;
    font-size: 14px;
    background-color: transparent;
    border: none;
    color: darkgrey;
}

.selectedDayButton {
    font-family: roboto;
    height: 40px;
    width: 100%;
    font-size: 14px;
    border-radius: 4px !important;
    background-color: transparent !important;
    border: none;
    background-color: var(--blue) !important;
    color: white;
}

.themeButton {
    width: 100% !important;
    min-width: 100px !important;
    height: 44px !important;
    border-radius: 22px !important;
    background-image: var(--orange-gradient);
    margin: auto;
    font-family: roboto-medium !important;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: black !important;
    -webkit-transition: all 200ms ease-in;
    -ms-transition: all 200ms ease-in;
    -moz-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
    border: none !important;
    overflow: hidden;
}

.themeButton:hover {
    filter: brightness(80%);
}

.themeButton img {
    padding-left: 10px;
    padding-right: 10px;
}

.loginButton {
    background-image: var(--blue-gradient) !important;
}

.inputTime {
    width: 50px;
    border: none;
    padding: 5px;
    background-color: var(--grey);
    border-radius: 5px;
    text-align: center;
    border: solid 1px transparent !important;
}

.inputTime:focus {
    outline: none;
    border: solid 1px var(--orange) !important;
}

.customSelectButton{
    height: 30px !important;
}

.colorPickerButton {
    border-radius: 50%;
    height: 30px !important;
    width: 30px !important;
    border: 0.5px solid var(--grey);
}

.colorPickerButton:hover {
    border: 1px solid grey;
}