.planningView {
    display: flex;
    flex-direction: column;

    padding: 0;
    height: 100%;
    width: 100%;
    background-color: var(--surface-ground) !important;
}

.groupesView {
    height: 56px;
    width: 100%;
    padding: 15px;
    padding-bottom: 8px;
    padding-top: 8px;
}

.schedulerView {
    width: 100%;
    overflow: scroll !important;
    padding: 0;
    padding-bottom: 80px;
}

.inputText {
    text-align: left;
    font-family: roboto;
    font-size: 18px;
    width: 100% !important;
    min-height: 40px !important;
    height: auto !important;
    border-radius: 20px;
    padding: 5px 8px 5px 18px;
    border: solid 1px #f5b826;
    background-color: white;
}

.label {
    font-family: roboto;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: normal;
    color: #000;
}

.clientLabel {
    width: auto !important;
    height: 34px !important;
    display: inline-flex;
    align-items: center;
    margin: 0;
    margin-right: 10px;
}

.samplesViewHidden {
    width: 100% !important;
    height: 0 !important;
    margin: 0 !important;
    margin-top: 65px !important;
    padding: 0 !important;
    transition: 0.2s;
}

.samplesViewShown {
    width: 100% !important;
    margin-top: 65px !important;
    padding-top: 10px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 0 !important;
    transition: 0.2s;
    overflow: hidden;
}

.topMenuButton {
    width: 34px !important;
    height: 34px !important;
    border-radius: 16px;
    color: var(--orange) !important;
    background-color: white !important;
    border-color: WHITE !important;
}

.topMenuButton:hover,
.topMenuButton:focus {
    color: white !important;
    background-color: var(--orange) !important;
}

@media (max-width: 768px) {
    .schedulerView {
        width: 102%;
        overflow: scroll !important;
        padding: 0;
        padding-bottom: 80px;
    }

    .planningView {
        display: flex;
        flex-direction: column;
        margin-top: 80px;
        padding: 0;
        height: 100%;
        width: 100%;
        background-color: var(--surface-ground) !important;
    }

    .groupesView {
        height: 56px;
        width: 100%;
        padding: 15px;
        padding-bottom: 8px;
        padding-top: 8px;
    }

    .leavesView {
        width: 100%;
        height: 56px;
        padding: 15px;
        padding-bottom: 8px;
        padding-top: 8px;
    }

}

@media (min-width : 769px) and (max-width: 1000px) {
    .planningView {
        display: flex;
        flex-direction: column;
        margin-top: 0 !important;
        padding: 0;
        height: 100%;
        width: 100%;
        background-color: var(--surface-ground) !important;
    }

    .groupesView {
        height: 56px;
        width: 100%;
        padding: 15px;
        padding-bottom: 8px;
        padding-top: 8px;
        margin-top: 20px;
    }

}