.headerContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Changer de 'left' à 'flex-start' */
    width: 100%;
}

.headerTitle {
    display: block;
    margin-bottom: 10px;
    font-size: xx-large;
    color: black;
    font-weight: bold;
}

.subTitle {
    display: block;
    color: black;
    font-weight: bold;
    font-size: larger;
    text-align: left; /* Ajouter text-align: left */
}

.topMenu {
    background-color: white;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Changer de 'left' à 'flex-start' */
    position: relative;
    text-align: left;
    background-color: white !important;
    box-shadow: rgb(9 30 66 / 5%) 0px 10px 18px, rgb(95 106 126 / 5%) 0px 0px 1px;
    border: none !important;
    border-radius: 0 !important;
}

.logo {
    position: absolute;
    left: 20px;
    max-height: 100%;
    max-width: 150px;
    height: auto;
}

.body {
    flex-grow: 1;
    overflow-y: auto;
    height: calc(100vh - 75px);
}

.container {
    background-color: #f5f8ff;
    border: 2px solid #ffa500;
    border-radius: 8px;
    padding: 20px;
    margin: 20px auto;
    width: 60%;
    font-family: Arial, sans-serif;
}

.sectionTitle {
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 10px;
}

.card {
    background: var(--surface-card);
    padding: 1rem;
    border-radius: 10px;
    margin-bottom: 1rem;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
}

.addButton {
    background-color: #FF9015;
    border: none;
    cursor: pointer;
    padding: 10px 20px;
    color: white;
    width: 150px;
    border-radius: 10px;
}

.form {
    width: 100%;
    padding: 20px;
    background-color: #fff;
    border: 2px solid #ffa500;
    border-radius: 8px;
    margin: 20px 0;
}

.modalContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.modalRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.modalRow label {
    font-weight: bold;
    margin-right: 10px;
    width: 30%;
    text-align: left;
}

.modalRow input, .modalRow select {
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    width: 65%;
}

.modalFooter {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 20px;
}

.addButton {
    background-color: #FF9015;
    border: none;
    padding: 10px 20px;
    color: white;
    cursor: pointer;
    width: 40px !important;
    border-radius: 10px !important;
}

.formGroup {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.formLabel {
    display: block;
    font-weight: bold;
    padding-right: 1rem;
}

.formInput {
    width: 100% !important;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.checkboxContainer {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
 
}

.checkboxLabel {
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
}


.errorText {
    color: red;
    font-size: 0.875rem; 
    margin-left: 0.25rem; 
}

.staticMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 98%; 
    text-align: center;
    font-size: 2em; 
    margin: 0 6em 0 6em;
}

.headerRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}
