.containerView {
    margin-top: 0px;
    padding: 0;
    height: 100%;
    width: 100%;
}

.groupesView {
    height: calc(100% - 65px);
    margin-top: 65px;
    width: 100%;
    overflow: scroll !important;
    padding: 15px;
}

.collabsCard {
    background: var(--surface-card);
    padding: 2rem;
    margin: 15px;
    border-radius: 10px;
    margin-top: 85px;
    height: calc(100% - 110px);
}

.emptyPageImg {
    width: 100px !important;
    height: 100px;
    padding: 0 !important;
    align-self: last baseline;
    margin-bottom: 15px;
}

.noGroupstext {
    text-align: center;
    border: none;
    background-color: inherit;
    display: inline-block;
    font-family: roboto-medium;
    font-size: 16px;
    color: gray;
}

.textBtn {
    margin: auto;
    border: none;
    background-color: inherit;
    cursor: pointer;
    display: inline-block;
    font-family: roboto-medium;
    font-size: 16px;
    color: var(--orange);
}

@media screen and (max-width: 768px) {
    .groupesView {
        height: calc(100% - 130px);
        margin-top: 130px;
        width: 100%;
        overflow: scroll !important;
        padding: 15px;
    }

    .collabsCard {
        background: var(--surface-card);
        padding: 2rem;
        margin: 15px;
        border-radius: 10px;
        margin-top: 145px;
        height: calc(100% - 160px);
    }
}

@media (min-width : 769px) and (max-width: 1000px) {
    .groupesView {
        height: calc(100% - 80px);
        margin-top: 80px;
        width: 100%;
        overflow: scroll !important;
        padding: 15px;
    }

    .collabsCard {
        background: var(--surface-card);
        padding: 1rem;
        margin: 15px;
        border-radius: 10px;
        margin-top: 90px;
        height: calc(100% - 95px);
    }

    .topMenu {
        height: 80px;
        width: calc(100% - 80px);
        position: fixed !important;
        top: 0;
        margin: 0 !important;
        padding: 15px !important;
        overflow: hidden;
        z-index: 1000;
        background-color: white !important;
        box-shadow: rgb(9 30 66 / 5%) 0px 10px 18px, rgb(95 106 126 / 5%) 0px 0px 1px;
        border: none !important;
        border-radius: 0 !important;
    }

    .topMenu>.row {
        height: 100% !important;
    }

    .topMenu>.row>div {
        height: 100% !important;
        height: 125px;
    }
}