.containerView {
    margin-top: 0px;
    padding: 0;
    height: 100%;
    width: 100%;
}

.groupesView {
    height: calc(100vh - 110px);
    margin-top: 65px;
    width: 100%;
    overflow: scroll !important;
    padding: 15px;
}

.collabsCard {
    background: var(--surface-card);
    padding: 2rem;
    margin: 15px;
    border-radius: 10px;
    height: calc(100% - 200px);
}
.filterContainer {
    margin: 15px;
    margin-top: 75px;
    margin-bottom: 10px;
    background-color: white;
    box-shadow: 0 .5rem .6rem rgba(0,0,0,.15);
    padding: 3px;
    border-radius: 10px;
}
@media (max-width: 768px){
    .filterContainer {
        margin: 15px;
        margin-top: 125px;
        margin-bottom: 10px;
        background-color: white;
        box-shadow: 0 .5rem .6rem rgba(0,0,0,.15);
        padding: 3px;
        border-radius: 10px;
    }
}
.input-group {
    flex-grow: 1;
}
.filterInnerContainer form {
    width: 100%; 
}
  
.modalDialog{
    max-width: 100%;
    min-width: 100%;
    --bs-modal-border-width: 0;
}
.collabCell{
    border-bottom: 1px solid rgb(226, 211, 211);
}
.modalHeader{
    position: sticky;
}
  
.modalBody {
    overflow-y: auto;
    max-height: calc(100vh - 200px); 
}
.modalFooter{
    position: sticky;
    border: none!important;
}
.resIconSize {
 height: 52px;
 width: 52px;
}
.buttonGroupStyle{
    background-color: #64748b;
    border-radius: 6px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.35);
}
.buttonShadow {
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
}

.buttonShadow:hover {
    transform: scale(1.06); 
    z-index: 1000;
}
.collabPoste{
    font-size: small;
    font-weight: 600;
    color: gray;
}
.collabEmail{
    font-size: medium;
    font-weight: 700;
    color: gray;
}

.custom-dropdown-panel {
    z-index: 1000; /* Ajustez la valeur selon vos besoins */
  }
  
@media screen and (max-width: 768px) {
    .collabsCard{
        background: var(--surface-card);
        padding: 2rem;
        margin: 15px;
        border-radius: 10px;
        height: calc(100% - 250px);
    }
}

@media (min-width: 769px) and (max-width: 1000px) {
    .filterContainer {
        margin: 5px;
        margin-bottom: 10px;
        background-color: white;
        box-shadow: 0 .5rem .6rem rgba(0,0,0,.15);
        padding: 3px;
        border-radius: 10px;
    }
    .collabsCard {
        background: var(--surface-card);
        padding: 1rem;
        margin: 15px;
        border-radius: 10px;
        height: 320px;
        margin-top: 90px;
    }
}
