@font-face {
  font-family: roboto;
  src: url(assets/fonts/roboto/Roboto-Regular.ttf);
}

@font-face {
  font-family: roboto-bold;
  src: url(assets/fonts/roboto/Roboto-Bold.ttf);
}

@font-face {
  font-family: roboto-light;
  src: url(assets/fonts/roboto/Roboto-Light.ttf);
}

@font-face {
  font-family: roboto-medium;
  src: url(assets/fonts/roboto/Roboto-Medium.ttf);
}

@font-face {
  font-family: roboto-italic;
  src: url(assets/fonts/roboto/Roboto-Italic.ttf);
}

.App {
  background-color: var(--surface-ground);
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.topMenu {
  height: 65px;
  width: calc(100% - 80px);
  position: fixed !important;
  top: 0;
  margin: 0 !important;
  padding: 15px !important;
  overflow: hidden;
  z-index: 1000;
  background-color: white !important;
  box-shadow: rgb(9 30 66 / 5%) 0px 10px 18px, rgb(95 106 126 / 5%) 0px 0px 1px;
  border: none !important;
  border-radius: 0 !important;
}

.topMenu>.row {
  height: 100% !important;
}

.topMenu>.row>div {
  height: 100% !important;
  height: 125px;
}

.breadcrumb {
  margin: 18px;
  margin-top: 70px !important;
  margin-bottom: 0 !important;
}

.headerTitle {
  margin: 0;
  padding-right: 10px;
  font-family: roboto;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--blue-vital);
  background-color: transparent;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.react-datepicker__day--keyboard-selected {
  background-color: #ef912b !important;
}

.react-datepicker__day--selected {
  background-color: #ef912b !important;
}

.textButton {
  background-color: transparent !important;
  color: #ef912b !important;
  border: none !important;
  font-family: roboto;
  font-size: 14px;
  font-weight: normal;
}

.textButton:hover {
  font-weight: bold;
}

:root {
  --red: rgb(251, 105, 98);
  --blue: #0067b8;
  --vital-blue: rgb(0, 40, 128);
  --green: rgb(58, 156, 96);
  --grey: rgb(227, 227, 227);
  --lightGrey: rgb(237, 237, 237);
  --darkGrey: rgb(217, 217, 217);
  --yellow: rgb(255, 255, 102);
  --orange: rgb(255, 144, 21);
  --orange-gradient: linear-gradient(90deg, #FF8701 0%, #FFC100 100%);
  --blue-gradient: linear-gradient(90deg, #1179be 0%, #2EAAFA 100%);
  --orange-light-gradient: linear-gradient(180deg, rgba(255, 144, 21, 0.05) 0%, rgba(255, 144, 21, 0.15) 100%);
}

.red {
  background-color: var(--red) !important
}

.green {
  background-color: var(--green) !important
}

.yellow {
  background-color: var(--yellow) !important
}

.blue {
  background-color: var(--blue) !important;
  color: white !important;
}

.vital-blue {
  background-color: var(--vital-blue) !important;
  color: white !important;
}

.orange {
  background-color: var(--orange) !important
}

.grey {
  background-color: var(--grey) !important
}

.lightGrey {
  background-color: var(--lightGrey) !important
}

.sideSticky {
  width: 100px !important;
  padding: 5px;
  position: -webkit-sticky !important;
  left: 0 !important;
  position: sticky !important;
  z-index: 998;
  display: inline-block;
}

textarea {
  width: 100%;
  min-height: 44px;
  border-radius: 8px !important;
  background-color: #fff !important;
  font-family: roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--orange)b !important;
  padding: 8px;
  border: solid 1px var(--grey);
}

.textarea-error {
  border: solid 1px red !important;
}

#uniqueTextareaId {
  all: unset;
}

textarea:focus {
  outline: none;
  border: solid 1px var(--orange) !important;
}

.react-grid-layout {
  min-height: 52px !important;
}

.react-grid-placeholder {
  border-radius: 5px;
}

.rotate45 {
  transform: rotate(45deg);
  transition: transform 0.2s;
}

.inputError input {
  border: 1px solid red !important;
}

.inputError select {
  border: 1px solid red !important;
}

.tooltip {
  background-color: rgba(255, 144, 21, 0.15) !important;
  border: 1px solid var(--orange);
  border-radius: 8px;
}

.circle-picker {
  width: 100% !important;
}

.popover-body .circle-picker {
  width: auto !important;
}

.table-striped>tbody>tr:nth-child(2n+1)>td,
.table-striped>tbody>tr:nth-child(2n+1)>th {
  --bs-table-striped-bg: transparent !important;
}

.error {
  color: red !important;
  border-color: #f73c2e !important;
}

.p-button {
  padding: 0.3rem 0.75rem !important;
}

.p-selectbutton .p-button.p-highlight {
  background: var(--orange) !important;
  border-color: var(--orange) !important;
  color: #ffffff;
}

.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}

.p-selectbutton .p-button.p-highlight:hover {
  background: var(--orange) !important;
  border-color: var(--orange) !important;
  color: #ffffff;
}

input[type=checkbox] {
  display: block;
  min-width: 20px;
  min-height: 20px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  -webkit-appearance: none;
  outline: 0;
  font-family: Manrope;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #0d0d0d;
}

input[type=checkbox]:checked {
  background-image: url('./assets/images/checkbox-full.png');
}

input[type=checkbox]:not(:checked) {
  background-image: url('./assets/images/checkbox-empty.png');
}

::-webkit-scrollbar {
  height: 6px !important;
  width: 6px !important;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: var(--gray-400);
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(245, 184, 38);
}

.customSelectButton {
  height: 34px !important;
}

.customSelectButton>.p-button {
  height: 34px !important;
  padding-left: 17px !important;
  padding-right: 17px !important;
}

.bg-even {
  background-color: var(--bluegray-50);
}

.bg-odd {
  background-color: var(--gray-50);
}

.bg-sunday {
  background-color: var(--red-200) !important;
  border-color: gray !important;
}

.bg-saturday {
  background-color: var(--red-100) !important;
}

.bg-holiday {
  background-color: var(--yellow-100) !important;
}

@media (max-width: 768px) {
  .topMenu {
    height: auto;
    width: 100%;
    position: fixed !important;
    top: 0;
    margin: 0 !important;
    padding: 15px !important;
    overflow: hidden;
    z-index: 1000;
    background-color: white !important;
    box-shadow: rgb(9 30 66 / 5%) 0px 10px 18px, rgb(95 106 126 / 5%) 0px 0px 1px;
    border: none !important;
    border-radius: 0 !important;
  }

  .topMenu>.row {
    height: 100% !important;
    align-items: center;
  }

  .topMenu>.row>div {
    height: 100% !important;
    align-items: center;
  }

  .breadcrumb {
    margin: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

@media (min-width: 769px) and (max-width: 1000px) {
  .topMenu {
    height: 85px;
    width: calc(100% - 80px);
    position: fixed !important;
    top: 0;
    margin: 0 !important;
    padding: 15px !important;
    overflow: hidden;
    z-index: 1000;
    background-color: white !important;
    box-shadow: rgb(9 30 66 / 5%) 0px 10px 18px, rgb(95 106 126 / 5%) 0px 0px 1px;
    border: none !important;
    border-radius: 0 !important;
  }
  .topMenu>.row>div {
    height: 100% !important;
    align-content: center !important;
  }
  .topMenu>.row {
    height: 100% !important;
    align-content: center !important;
    align-items: center !important;
  }
  .breadcrumb {
    margin: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
.p-listbox .p-listbox-list-wrapper .p-listbox-list{
  padding-left: 0;
}  
.p-dialog .p-dialog-content{
  overflow-y: auto;
}
ul{
  padding: 0 !important;
}
a{
  text-decoration: none !important;
}

.p-togglebutton .p-button{
  background-color: #dc3545!important;
  color: white;
}

.p-togglebutton.p-highlight .p-button{
  background-color: #28a745!important;
}

.p-datatable .p-datatable-thead > tr, .p-datatable .p-datatable-tbody > tr {
  border-width: 0 0 1px 0 !important;
  border-color: lightgrey!important;
}